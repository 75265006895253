class MileageMoneyNew {
  $scope:            any;
  $rootScope:        any;
  $location:         any;
  $compile:          any;
  api:               any;
  authService:       any;
  appFactory:        any;
  assignmentFactory: any;
  loaderFactory:     any;
  errFactory:        any;

  loaded:            boolean;

  assignments:       any[];
  assignment:        any;

  selectedWeek: any;
  weeks:        any = null;
  constructor ($scope, $rootScope, $location, $compile, API, AuthService, AppFactory, AssignmentFactory, LoaderFactory, ErrFactory) {
    Object.defineProperties(this, {
      $scope:            { value: $scope            },
      $rootScope:        { value: $rootScope        },
      $location:         { value: $location         },
      $compile:          { value: $compile          },
      api:               { value: API               },
      authService:       { value: AuthService       },
      assignmentFactory: { value: AssignmentFactory },
      appFactory:        { value: AppFactory        },
      loaderFactory:     { value: LoaderFactory     },
      errFactory:        { value: ErrFactory        }
    });

    this.$rootScope.mileageData = null;

    this.loaded        = false;
    this.assignment    = null;
    this.selectedWeek  = null;

    Promise.resolve()
    .then(() => this.authService.loadUser())
    .then(user => this.checkMileageConfig(user))
    .then(valid => {
      if (valid) this.initLoad();
    });
  }

  private initLoad(): void {
    if (this.appFactory.isNetwork()) this.loadAssignments();
    else this.addWatcher();
  }

  private checkMileageConfig(user) {
    if (user && !user.license_plate) {
      let angElem  = angular.element('<mileage-money-popup license-plate="null" license-plate-country="\'DE\'" on-hide="onHide(ind)" callback="true" on-callback="onCallback()"></mileage-money-popup>');
      let newScope = this.$scope.$new(true);

      newScope.onHide     = () => this.$location.path(`/`);
      newScope.onCallback = () => this.initLoad();

      angular.element(document).find('ng-view').append(this.$compile(angElem)(newScope));
    } else return true;
  }

  private loadAssignments(): Promise<any> {
    this.loaderFactory.show();
    return Promise.resolve(this.api.getValidMileageDays())
    .then(res => {
      this.addWatcher();
      this.loaded      = true;
      this.assignments = res?.mileage_data?.map(a => {
        a.id = a.assignment_id;
        return a;
      });
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  private addWatcher(): void {
    this.$scope.$watch( '$ctrl.assignment', (newVal, oldVal) => {
      if (newVal !== oldVal) this.changeJob();
    }, true);
  };

  changeJob(assignment = this.assignment): void {
    this.selectedWeek   = null;
    let assignmentEnd   = Weeks.getEndOfWeek(assignment.ends_at || Weeks.getEndOfWeek(new Date)).valueOf();
    let assignmentStart = Weeks.getStartOfWeek(assignment.starts_at).valueOf();
    let weekStart       = Weeks.getStartOfWeek(new Date);

    let start = new Date(Math.max(
      assignmentStart,
      Number(weekStart) + Weeks.getWeeksSize(weekStart,-6)
    ));

    let end = new Date(Math.min(
      assignmentEnd,
      Weeks.getEndOfWeek(new Date).valueOf()
    ));

    this.weeks = Array.from(new Weeks(start, end)).reverse();
  }

  loadMM(): Promise<any> {
    let start = new Date(Math.max(new Date(this.assignment.starts_at).getTime(), Weeks.getStartOfWeek(this.selectedWeek).getTime()));
    let end   = new Date(Math.min(new Date(this.assignment.ends_at).getTime(),   Weeks.getEndOfWeek(  this.selectedWeek).getTime(), Weeks.getEndOfWeek(new Date).getTime()));

    this.loaderFactory.show();
    return this.api.loadStandaloneMileageReport(start, end, this.assignment.id)
    .then(res => {
      let mileageData        = res;
      mileageData.assignment = this.assignment;
      mileageData.startDate  = start;
      mileageData.endDate    = end;

      this.$rootScope.mileageData = mileageData;
      this.$location.path(`/mm-confirm`);
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  back(): void {
    this.appFactory.goBack();
  }

}

window.app.component('mileageMoneyNew', {
  template: require('scripts/components/mileage-money/mm-new/mm-new.html'),
  controller: ['$scope', '$rootScope', '$location', '$compile', 'API', 'AuthService', 'AppFactory', 'AssignmentFactory', 'LoaderFactory', 'ErrFactory', MileageMoneyNew]
});
