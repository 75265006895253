class MileageMoneyList {
  $scope:              any;
  $rootScope:          any;
  $location:           any;
  appFactory:          any;
  api:                 any;
  loaderFactory:       any;
  errFactory:          any;
  databaseService:     any;
  mileageMoneyFactory: any;

  columns:    any[]   = [{ label: 'activityReport.period', sort: 'start_date' }]
  column:     string;
  page:       number;
  reverse:    boolean;
  state:      string;
  totalPages: number;

  mileageReports: any[];

  private readonly COLECTION_NAME = 'mileage_money';
  constructor($scope, $rootScope, $location, AppFactory, API, LoaderFactory, ErrFactory, DatabaseService, MileageMoneyFactory) {
    Object.defineProperties(this, {
      $scope:              { value: $scope              },
      $rootScope:          { value: $rootScope          },
      $location:           { value: $location           },
      appFactory:          { value: AppFactory          },
      api:                 { value: API                 },
      loaderFactory:       { value: LoaderFactory       },
      errFactory:          { value: ErrFactory          },
      databaseService:     { value: DatabaseService     },
      mileageMoneyFactory: { value: MileageMoneyFactory }
    });

    this.prepareDefaults();
    this.loadReports();
   }

  private prepareDefaults(): void {
    if (this.$rootScope.paginationPage) {
      this.page       = this.$rootScope.paginationPage;
      this.column     = this.$rootScope.paginationColumn;
      this.reverse    = this.$rootScope.paginationReverse;
      this.state      = this.$rootScope.paginationState;
      this.totalPages = this.$rootScope.paginationTotalCount;

      let col = this.columns.find(c => c.sort === this.column);
      if (col) col.reverse = this.reverse;

      this.$rootScope.paginationPage       = null;
      this.$rootScope.paginationColumn     = null;
      this.$rootScope.paginationReverse    = null;
      this.$rootScope.paginationState      = null;
      this.$rootScope.paginationTotalCount = null;
    } else {
      this.column     = 'start_date';
      this.reverse    = false;
      this.page       = 1;
      this.state      = null;
      this.totalPages = 0;
    }
  }

  private loadReports(): Promise<void> {
    return Promise.resolve()
    .then(() => {
      if (this.appFactory.isNetwork()) this.refreshReports();
      else return this.loadLocalReports();
    });
  }

  changeTab(state: string): void {
    if (this.state === state) this.state = null;
    else this.state = state;
    this.page = 1;
    this.loadReports();
  }

  sortCallback(column: string, reverse: boolean): void {
    this.column  = column;
    this.reverse = reverse;
    this.loadReports();
  }

  paginationCallback(page: number): void {
    this.page = page;
    this.loadReports();
  }

  private refreshReports(): Promise<void> {
    this.loaderFactory.show();
    return this.api.loadMileageMoneyReports(this.page, this.column, this.reverse, this.state)
    .then(res => {
      this.totalPages     = res.meta.total_pages || res.meta.paging.total_pages;
      this.mileageReports = res.standalone_mileage_reports.map(r => new this.mileageMoneyFactory.MileageMoneySubmitted(r));

      this.$scope.$apply();
      return Promise.all(this.mileageReports.map(data => data.save(this.COLECTION_NAME)));
    })
    .catch(err => console.error(err))
		.then(() => this.loaderFactory.hide());
  }

  private loadLocalReports(): void {
    this.loaderFactory.show();
    let extraFilter;

    if (this.state) extraFilter= { field: 'state', trueOptions: this.state ? [this.state] : '' };
    this.databaseService.getLocalPagination(this.COLECTION_NAME, this.page, this.column, this.reverse, extraFilter)
    .then(res => {
      if (res) {
        this.page           = res.page;
        this.totalPages     = res.totalCount;
        this.mileageReports = res.standalone_mileage_reports.map(r => new this.mileageMoneyFactory.MileageMoneySubmitted(r));

        this.$scope.$apply();
      } else {
        this.$location.path('/projects');
        throw new this.errFactory.NoInternetConnectivity().notify();
      }
    })
    .catch(err => console.error(err))
    .then(() => this.loaderFactory.hide());
  }

  showSubmitted(item): void {
    this.$rootScope.paginationPage       = this.page;
    this.$rootScope.paginationColumn     = this.column;
    this.$rootScope.paginationReverse    = this.reverse;
    this.$rootScope.paginationTotalCount = this.totalPages;
    this.$rootScope.mileageMoney         = item;

    this.$location.path(`/mm-submitted/${item.id}`);
  }

}

window.app.component('mileageMoneyList', {
  template: require('scripts/components/mileage-money/mm-list/mm-list.html'),
  controller: ['$scope', '$rootScope', '$location', 'AppFactory', 'API', 'LoaderFactory', 'ErrFactory', 'DatabaseService', 'MileageMoneyFactory', MileageMoneyList]
});
