class FeedbackForm {
  $scope:        any;
  $location:     any;
  api:           any;
  notification:  any;
  loaderFactory: any;

  rate:       number;
  suggestion: string;
  constructor($scope: any, $location: any, API: any, Notification: any, LoaderFactory: any) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      $location:     { value: $location     },
      api:           { value: API           },
      notification:  { value: Notification  },
      loaderFactory: { value: LoaderFactory },
    });
  }

  submitFeedback() {
    this.loaderFactory.show();
    const data = {
      feedback: {
        feature_name: "General",
        rate:          this.rate,
        message:       this.suggestion
      }
    };
    this.api.postFeedback(data)
    .then(() => {
      this.loaderFactory.hide();
      this.notification.alert({title: 'feedback.successfulTitle', desc: 'feedback.thanksForFeedback'});
      this.$location.path('/');
    })
    .catch((error: any) => {
      this.loaderFactory.hide();
      this.notification.alert(error);
    });
  }

}

window.app.component('feedback', {
  template: require('scripts/components/feedback/feedback.html'),
  controller: ["$scope", "$location", "API", "Notification", "LoaderFactory", FeedbackForm]
});
