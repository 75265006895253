class MileageMoneyPopup {
  constructor($element, API, AuthService, ErrFactory, LoaderFactory) {
    Object.defineProperty(this, '$element', { value: $element });
    Object.defineProperty(this, 'api', { value: API });
    Object.defineProperty(this, 'authService', { value: AuthService });
    Object.defineProperty(this, 'errFactory', { value: ErrFactory });
    Object.defineProperty(this, 'loaderFactory', { value: LoaderFactory });
    this.loading = false;
  }
  
  submitForm() {
    this.loading = true;
    this.loaderFactory.show();
    let params = {
      user_license_plate: {
        license_plate: this.licensePlateNumber.trim(),
        country: this.licensePlateCountry,
        signature: this.signature
      }
    };
    Promise.resolve(this.api.updateLicensePlate(params))
    .then(res => this.authService.getAuthorizedUser().update())
    .then(() => {
      this.licensePlate = true;
      this.loaderFactory.hide();
      this.loading = false;
      if (this.callback) {
        this.onCallback();
        this.$element.remove();
      }
    })
    .catch((e) => {
      if (e instanceof this.errFactory) e.notify(() => this.loading = false);
      else {
        console.error(e);
        this.loading = false;
      }
    });
  }

  skip() {
    this.$element.remove();
    this.onHide();
  }

  hide(ind) {
    this.$element.remove();
    this.onHide({ind});
  }
}

app.component('mileageMoneyPopup', {
  template: require('scripts/components/kilometergeld/mileage-money-popup/mileage-money-popup.html'),
  controller: MileageMoneyPopup,
  bindings: {
    licensePlate:        '<',
    licensePlateCountry: '<',
    onHide:              '&',
    callback:            '<',
    onCallback:          '&'
  }
});
  